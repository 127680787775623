<template>
	<div id="organizationspages"  class = "pagefixedwidth">

		<div>
		<!--  v-if ="tabsobject['typeofpage']=='cockpit'" :classes="'cockpit'" :activetopbar="activetopbar" @showdescription ="oncockpitdescription()" :hovertitle="cockpit.hovertitle" :backarrow="true" :pathback="{path: '/boards'}" @opentabs = "opentabs" :tabsobject ="tabsobject.tapes" -->
		<TopBar :tabsobject = "tabsobject.tapes" @opentabs = "opentabs" hidedropdown>
			<template v-slot:title >
				Organization
			</template> 
		</TopBar>
		<main class="main-wrapper"> 
			<router-view name ="organizations"/>
		</main>
		</div>
	</div>
</template>
<style lang = "scss" scoped>
@import '@/scss/abstracts/variable.scss';
	@import'@/scss/abstracts/mixin.scss';


</style>
<script type="text/javascript"></script>

<script>
	import {TopBar} from '@/components/topbar'
	import {about, members} from "./components"

	import auth from '@/cognito.js';

	import Signinapi from '@/services/api/signin'
	
	export default{
		name:'organizationsView',
		data(){
			return{
				tabsobject : { tapes: [], currenttap: 'about', tabsobject: 'organizations' },
				jwtinfo : {}
			}	
		},
		computed: {

			tabs(){

				var tabs = []

				tabs.push( {title:'About', route:'/',  name:'aboutorganizations', current:true } )
				tabs.push( {title:'Members', route:'/members', name:'membersorganizations' , current:false })
				tabs.push( {title:'Teams', route:'/teams', name:'teamsorganizations' , current:false })

				if( this.myrole == "ADMINISTRATOR" ){
					//tabs.push( {title:'Teams', route:'/teams', name:'teamsorganizations' , current:false })
				}
				

				//var toreturn = { tapes:tabs, currenttap: 'about', tabsobject: 'organizations' }

				return tabs
			},
			organizations(){
				return this.$store.state.organizations.selectedorganizations

			},

			userinfo(){
            	return this.$store.getters.user
          	},

          	userid(){
            	return this.userinfo._id
          	},

			myrole(){

				var sharedtousers = []

				if( this.organizations.sharedtousers){
					sharedtousers = this.organizations.sharedtousers
					var me = sharedtousers.find(x => x.userid === this.userid);
					return me.role.toUpperCase()
				}else{
					return ""
				}
				 


			},
			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },
		    ssorequired(){

		    	/*if( this.organizations.requireauth != '' && this.organizations.requireauth != undefined ){
        			return true
        		}else{
        			return false
        		}*/

        		 if( this.jwtinfo.profile == this.organizations.requireauth  ){

			      return false

			    }else{


			      if( this.organizations.requireauth != '' && this.organizations.requireauth != undefined ){
			        return true
			      }else{
			        return false
			      }

			    }
		    }

		},
		created(){

				if (auth.auth.isUserSignedIn()) {


	            	var jwt = Signinapi.getjwtUserInfo()

	            	jwt.then( function(response){

	             		self.jwtinfo = response

	             	});	

	        	}

			},
		mounted(){

			

			document.title ='Organizations | Flowe'

		},
		watch:{
			'$route.fullPath'(){
				this.opentabs(this.$route)
			},
		},
		methods:{
			 opentabs(tab){

	          let self = this

	          

	          var thisroutename = this.$route.name

	          if(  thisroutename == "teamsorganizationsteam" ) {
	          	thisroutename = "teamsorganizations"
	          }

	           this.$router.push({name:tab.name, params:{orgname: this.orgkey}}).then(()=>{
	           
	           }).catch(err => { 
	            //console.log(err)
	           }).finally(() =>{
	           	 for(var i in self.tabsobject.tapes){

		             if( thisroutename == self.tabsobject.tapes[i].name){


		              self.tabsobject.tapes[i].current = true
		              self.tabsobject.currenttap = self.tabsobject.tapes[i].name

		              
		             } else {
		              self.tabsobject.tapes[i].current = false
		             }
	            }
	           })

          


        },

		},
		mounted(){
			let self  = this

			this.tabsobject.tapes = this.tabs

			setTimeout(function(){
				$('#preloader').addClass('hide')
				//self.opentabs(self.$route)
			},100)

		},

		components: {TopBar, about,members}
	}

</script>